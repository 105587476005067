import React from "react";
import Footer from "./footer";
import cusatimg from "../images/cusat2.png";
import './animation.css';

function gallery() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
      <div className="w-full h-auto">

        {/* <header>
          <h1>Showcase</h1>
        </header> */}
        <main >
          <div > 
          <section >
             
                        <div class="scroll-indicator"></div>
                  <div class="container">
                      <h1>Etchnew 2023</h1>
                      <div class="gallery">
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPFf9ipD8nKBIiiZwPpTl4sJXjQkcHHny9y-i62FfLaU2CjMkJke7pxvtLybYSaUSk6L93byglp5ZMr98_R36m5dVPyTIs20kjWJXTfMfSIEOT2UGDkkUT6zaqpd99LYZwTq3SkgLSUV-IUC0tS0f2Y=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNhYL_aEzLrDpGOCwExpnmh8M0Px0WvroIjxgGT9fZKyLpPq8xL0OChs-8F83kTCzkFW2aN6DFUr26uq5imT4SYum6rLl6TZERZj876QVuMYkFVl0g_BsNYoVjTvc-RdFNWc4HEPE-24o5MPuJuOAAW=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczP6kNbqaFcfJ5ghcoZAEXZ9x-UfIL5sIfz9Zk84GpwXU1_7Yq2gy_EaPV8gwKsjQPFMe6Jw1ryCSoAD_2SZwIWZxaHyrvpa7iLyczETQgO1GB0UuixNasyWs4IhGRW6Xj5VIlHzNSFPCNm_O9kU2OsRow=w1385-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPJh_Kirr5yUk05pCeaGGJR9LCBZueYCbMPAghcqwhsqD2YpK3nfyCZqlzMBNXTmwcuvW3bZMaR99ugwtHpq5WIdegIMuZ8U9ZPY0L3GSC-OPpxybpXpOwUVx4fNg2pEGS3CkDbV9jaKf_wAAHRGe5huw=w1385-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMX9a-gLe_nsm1GIhwW2h20O8WfsVf7eOiCn4IuBYtawmvFelRztGDa651ZHEidnALlVS-qniOISn6xufXpeyuojM9pdAyVuAV6u-xDhYPDjX8H0x605mBq2O3U-e-GNSXaXPXRFuZI2qGuavzaX8u8=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNgx8YizcgpLFybvZFEa1Hu7K_gW-ypASyiti1Ofs-beM8dbjS4MBh6nfwohlyM0oYJut4xz1K9XVy352zC2JoCNP_a3ftPvM4ZYBzDbpul1XarNZEcgrgVAW83aQZHiQpP4ApA_DWsd7MuCwcAWa35=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                          <img src="https://lh3.googleusercontent.com/pw/AP1GczPUGZFSYNXBv-P38LUaLCMzeNEXP7Qa-3EONcB2hMPcdOFm6zbSBmyGadMKqUlFBYNXM2jiBd5ZyaFpkf5mImqlwFnIIj6yZjCqGMb7x7QYhyXx9om378O2okwN_rg4n2-I8WzRpKtW2dNEFpGwOB_N=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNXpPb5Fgn54Hn506UOLHCswU3PzZKH_UFZhByauncc1MibnL8bxMU4ZazLnuuY7Xwy5oy8F5egISI35Bnupi1iHdbMsT3eiyQr0jnaRa8mUfTMgK9i2sE_XK0gOGtmvbmA1lxHdgWWwcwLEuTrglm_=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNkFSIv117xjG1TjA-NAv8pncBAhzQ2YB6Eozu14Zl_C7-qDvm2OCpY2qtUjakjopmjA6c-uwsyRFys0DMT6IhvWpIRn3dUncOxjsRq_65M3sOazQPsC6HOieM1Kk6HJXAEDJj95ab_hMtCqcKKSX5U=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMMithnElRu4xKK4ZJBHvspyJw-UD5aWnYmV7ARmlDtv3Bn_LKU2CCmjjZSX4NkXBE80CfHkwB616tKSVky1WPF-RXo5SdPk1CTs1sFgst2w-JkV_B4hiHFy1gZd8soMSv1R0Cmz0yRykQKGFFlqEd9=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPwaiz7ByNatQPMCmbX_0gfoWcW11gWipjEifmCXpCH8VmNqJN8_X2gjQnqSaFHl8VNSd95EQUcZehXDFuHFWTQKPdrusVD8-_KPVYKrC-WKK4dXPBMqD0Wg2yUSWCACtQqSaDotvfs2_EmlESVEpI=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPp8clqWVYumLSyVOofSihpZfc-nKTlF56SUq-S0Seb25J8FBqK_OwSE85JJamWcug_9qNjcQTGGW4fLlHK4n24vvwdS1mebyGC7pVvdEUnP0fLXevIjrmIBglH83DTh_E9DOVjzlFtphJhrW3nnMc=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPydxTfTEFK1mT1K38jIi1qoYAe-fV73tclrcp20hnZEyDzaj6n5IvJzuDVmgHl_eRde8GJ3sTvVgtgILeB7qez_uLkrKcvGHlb24Ebzh4V2mfEFXa_fSgSq1f0jYhu2yITHdLnkyuu6QEh9wHdW_tO=w1559-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMj__rPK03XEqWLHhSJwK9otktgB80Ly1ZpnYvGOFWF4B6kou6q8-RXOwaHlTBFcexxFHe6K0RE4WspQOJ7VrzzJnkPyprhPyoU5yqFwecHw1ivb680ZncXPYsTA0YVxPo2Z24y47ia9vZROkDrGRSR=w693-h1039-s-no-gm?authuser=0" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczN7Lra5t8-xEN-zs5RJULA0r4Gxb4iKpJ0QAN0PdYng1dyRRq8PGVbh2mzf1FgQkpzvu52IyHpkM2uawf_POFGtucjvpANIyJQFOdkV7izBu1WabNJRx4pFuxSKyie2rCgEVO1oqV7J3NogPihpB1C4=w1369-h913-s-no-gm?authuser=1" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOR7ob2SIwUrYPzPy2gt6vJo6GSs38ewOiyXjxhFuduyvuWGjzVp5_FAbDGkzagLVowzql3E3Lp7PrhNERQzhGlBvYT7IV7rcSBEgpA2VGUf2oLIeNKsS2HyzAylX7dQfMVTheuTgkhLdvJLvQOpY7y=w1369-h913-s-no-gm?authuser=1" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO4BxKbkXkVqlLMorZohgDE7pHhKKsNLEW1qBB2gnv2ZPhOZ5TjkF5LiX2AbNiEiV_XyocdArb5Mk8iOEjURKaOUfhX_y3jnjERXc7-mabUu92LvwHIwUxFhPO1JNmYvp4vxOw3TcCD37FCXpjMQiCl=w1369-h913-s-no-gm?authuser=1" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOvcB0_XQNo-sAtc67HFCivKGMVETuCg3nuHpRTMf0VY0f8hzDG01Pc0mc-HEkMqKwGTtx0Z6xajzaP9lyu5FZKLKn6XnDMwM8zk95VhwhMNZ_CfFC-onPMN5eNVYI1_PbpVt63lW0v2qYyYCEztinj=w609-h913-s-no-gm?authuser=1" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPZGrvYv0x3OdaKzFD8f6Jai5Eq4H0RrgEigJcSUpOnEXwyH2g2JzC0Slh9sTtnnEEX7D1qr48DuB9PrfgdK6t5ZGxoWy2ZGp_88VjcYQxVrOfYRABG_zW7mp6O3nu3gdaICac7R8TBEz5yonnH2703=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO5-N5I5YoKhTo8JIO77uENtvagHqYJf7TLFHtzDiljh-7J8Cu6oRR6M21zxJ0xv1TH7fdPTZAAhBtj4t7AaKuWHJIgsjmbL84ja_79R6ky00CJcKn1oY3_eyNVLS-qDQsjkdRgp98m7vtq36rtMbWA=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOsbRQM_zelPjCd_dDnOxlTCdIZcGRkGNfV2BaQaIYZ45aDH8vKWkW_LS0GMeohSABMzI36dO-HP40htfIMafDHAR8JDnUbBoFO_0AKIaqP_C0BCvD_MXLIUj6p9138dio6iWnzBKM5deMF_a4GtPXG=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczP4ILroK5u8q4_MfXJCP_h-xzdnSdYW3Mmd2dQQrE2b4HueHhSGEtSHtgMh9IPVJVsPV9EuIfQN2xxCa8ymAINFY28fNv3uRQMIR2_fl80QYXV1__C0Dwl4SmIqw-30boUsOGh_2wkOM9y1LFZaF60k=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczP0s90HodUloV9t6x2C-TtP5gGAK8gO7S0RkpKyhg2EbwyipRInswANaeL2UQqZiafTI5FrV0Afq7TaR3QfPro5x6QOjVyKTDRc_bx3V-kD4TJ8kFiLPr34NHG2fM9a_V9pR3R28TIU2-sGweikHArB=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNCjHesEP6altvqg9YQXHY0zQEp_lgAckelAVSX5lZHu04aNPy5n5gYqfFeNoPK77E3WhUMQr5tt-tcpjjSdMVDLQo64sAUOrWJo-wwkIfRi96Wh94h4IX8iIA1oFKRlxPglDHT4vaFKHcaWxldEcUo=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOhC8WkxNN2KRbtfyf5giXdm9vZg4dtYLrKabsTUUd8vSiaWPkf6O-1FRydFdeEvcZ4qVd0vh2jQvZrLke4uL5axr-QlMvpM4Jkexzc8G3w_ErVk7FZFsU-tm7LV8JXK58ELbkesSCAQN34Mo5NYvj5=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNfYhY0TO67RPJJH4dbVPGhLbyFcuMAltaZBeDL1NDyLitiJpJE530Miwuk2Sh47r-wc5CO5zMwXCfOPcnLntu6bU2gZ6QsqrtGKfARYnuunbrHTvFI1eiqQ3AwFCErEPJjpS4CvYa2A80WLibOPE64=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOVmTxemY4vTT3qYEGP0rO0-Avh0IfCY3s1uwiitvRM7GjUOFcuAdVJfR1BON231Yjd7BKPdC6DsNZTKMbVR8cnQ5j4dwtBbf1abD201V0oil50wfWuukllzN9BVvBSDCaIbF6ieX6LcX0xH2qsBHql=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOPF6OL4vgg2fWmoxF2Gkw84OGO1Mqd7gbuVI7JavBIHsG2pm6OwP5nDeLjiqxKwicWRbovCBwXi31QxRTlz2g_rxk7vg7rR_P3-1DwTCyE0hCCfAhKda3BH5Ka-8YkROsYebqYlYP3pOyYL3lEffzu=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO7wIIwup-URwOvPLr6L2S91ZC6HFBuIjCM3BDpP-mb3loIJZBujK8LLhonpp8IY34qtNDO0_jOAjvNt00hHR9MW8nj0BoCd4LhCSCO3n7ZXKw86yOmeHF67YN4RlrTmvyLX3TFhoibRsWr-vTIxgQR=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczN0L29R-SSBTWt2lJZCegG9RD3ydmCa6LsUz3nBtjblh5XZSiWo56KyfQT04_5AGkFlQOweICh7p_AgaZf7qlcRxWdrExf-i0lp_Uvf_QRXlBdQgkzHHa3GEx6pl9MV429kiOgh2luOH1SErnqZRm2W=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO7EyAlejiUZiX-E_V1hy9wKJDN5wmTEQLQxDBkG3gxCjaClmjGHwyrysclyEnJgM2Ggy60NyrvYgfZNr5BBjV_YYdRzeU-QN8MdrzFtWWy4Myjiv_ZppPGfQA1z0y2b_DiUEBxVE80QDifHfV7o9oA=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOPvehehMw7XD4kyLfddLJMMe2siWScSX0lAQ10OESFwvhbF6MTCVt98wzOar5chvJwiSTQDpqhQRIswyWI5cN_w2x8yIpc_YivlGsibatH-2gUTjczebkaLoc_H7p6axRfO2_RdNfzDYdzuRk04zpQ=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMN6cky43xmIWdTjJ4Fhe-mhyOanVeYuMs7c9oDTJhsYwoeoDvAiOaZSws1KkaLUMXIK6oojEa9O5o06XKF292vuAUEQ7vjdFKLSTusOPDyYvaawY6F5ped3LbgS9mrUi57a2cRDNYLe69PmnET0zSI=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO0FqbZrTEGAKU585YgXqwOwqjmPch1gycNjA0vwA4JPM7L67RHSHhhmMBMxkC4BPFCr6Z_VQDpYbA7YIv9mnXLDDP2ql-Zrce9wK1vX5hmfF-Ggh15A9Q-_-85RvYBJVCgG-vPEkj_BjX52Ca5lZfl=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNth7KxmUFgUSk5wIGwHZF7Ur-Y1l_SJsb_cM6MINqumGaNKxBvrzKvVpzCgc500IDLRyXa7wj83d9hCOedGfnx8wcpex88efvEhssn6Fpo3rPHHiKiGv4n1l9dvBydU2Q4gKxhUxNF9zrKjJ71AeM2=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczPjogOwj8C-Wn8gl_9CWl3J62I79v6aN0LUqYkIphtBPFhKj7h7THmrqhlg1Fv94oBPeHtytPeJ7wsIJtbjYHz4RUC-fUIpYRCrqtXZd73va_mUdFdaeCbyVFo0av8bgvRI1Lj1yMgNdk3hn0q3Tdk=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMafem5jS5KPI7kBhGo-VsDBGLBMs26DeOFLhn-YN85d5G4GGMHgrt0uBbdUnJ1ZJtZMvrX3LJ044pWsuSwYEdc4R2yUqndQILAUFuh0M7_mL5cxjeCeyUnIPxs_dhBmNvno37Yndo79QW0ReXEsnI=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczNOfGBjasBoBWt3d9YKDA0XMZ465Rro3x4gEh0dfCJTwYg1EG3TYSCgNZ-P_YbVRbeMkdRJPcxakxc6ExdyHzXMA3fwolCd9AW_7HCgiR936DTQVn5D2KInezK0zVt5apNf_X64HXPAWQUuv6k42wdb=w1920-h864-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOQ2rZYNUZzgtR9pO9PtOw7CC3bC2hqgT6Xy5dDmGTxrFQUfaqhpUvUtuWBeeTtCVBzLLUfPrvpbvyI2iDrcQOTHT8Gn-rgEAbntFs50tEss7b-LmWFD6NOUZTRUOGzZCuT1WN-XaV2fApRntzCkR4=w685-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczO7waTLKcv0_Uu_f5CjzP5aggr09eNTPz9L4wNxn8moZVzO-BTP-7YMWWv-4IIheDFrJzBZ67jvyESW1W5ntbpk3LeMiP9bEKyPw_2y_OmEn77eykDOvHwKK6BSGoaiRahFTqO3gtJUGE64Mi0g2yg=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczMUsN1WZyFJ6RPDVn8QnyNCJXOVuc5x69TVCo_zxNHKA-0DaWuTQm0A-xNPuFQCiQPjydp9qdjvnA9DDUHI0KugphcqJNFErJ3Xg6FjFnLVZtDqCWgbWgX93FimzfqNGfTg0I1wb_SYqpIw4X2XrwU=w609-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOz0zEkxO107smE4KMY1YxDkD2LbelJYR2VET8ToePa1cLB4_BlyPsa2U9aWZqyQi21jfc0AyfT0KaM5KtLSpc0dD5KngqZuMx0__CSQWR2AUQ_PgC0cQcl7TZlia9JeTxaEJ8YfmQPYaAL6TH36JY=w1216-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczOPsRG1HeISvNA5-si5WAVuHs1omOk5566YJcfDWvifkZl_a0R3BRmyX_cHCTqfjmxNAeprHn8JdWEXuaTbm55Tg2oKzlBgrgvLinVcDbEEWb7sRWGu5lH9ILxrpTQ4ZeEhBLjKo1SbcKoMYrSxN_cxPg=w1623-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczN_pX8lqJ27pUfUNoEoGa3JI68REBcyIs3ruGS3Nst3MVMFc4PCMmWgARiI2Q48W7puzxX7nOscKplxM2OG_sJwiSeH7pcq7RMjCq9lIyO6oavoecBDEXkeOatxk6OGCiQUaLgWsxBsODpCwGFLm1Oq=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>
                          <div class="gallery-item">
                              <img src="https://lh3.googleusercontent.com/pw/AP1GczP1hOlR6RTCsz9nX9ZD6FzE3t9B37rJ2NqaBQFvacQwpLUUVkVliP9P__rbgfSHV_X587kRE2D4cHyGxRGPecU2oG6osEsqojM5Ig3UJUxeze0n2eh-frixALCJCcxBEncJ_fTNgL2FeFxuA5Nl56Q=w1369-h913-s-no-gm?authuser=1" alt="Etchnew images" />
                              {/* <div class="overlay">
                                  <h2>Alpine Majesty</h2>
                                  <p>Towering peaks pierce through misty clouds in this dramatic Etchnew images.</p>
                              </div> */}
                              {/* <a href="https://unsplash.com" class="photo-credit">Photo by Unsplash</a> */}
                          </div>

                      </div>
                  </div>
                  <script src="script.js"></script>
            </section>
          </div>
        </main>
      </div>
    <Footer/>
    </div>
  );
}

export default gallery;


































// //               </a>
// //               <a
// //                 className="hover:scale-110 transition duration-200 w-fit"
// //                 href="tel:701-244-1198"
                
// //                 import React from "react";
// //                 import Footer from "./footer";
// //                 import cusatimg from "../images/cusat2.png";
// //                 // import "./gallery.js";
// //                 import "./animation.css"
                
                
// //                 function gallery() {
// //                   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })};
// //                 window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
// //                   return 
// //                     <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
// //                       <div className="w-full h-[200vh]">
                
// //                         {/* <header>
// //                           <h1>Showcase</h1>
// //                         </header> */}
// //                         <main >
// //                           <div > 
// //                           <section >
                             
// //                               <div class="imageGallery">
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://lh3.googleusercontent.com/pw/AP1GczP6kNbqaFcfJ5ghcoZAEXZ9x-UfIL5sIfz9Zk84GpwXU1_7Yq2gy_EaPV8gwKsjQPFMe6Jw1ryCSoAD_2SZwIWZxaHyrvpa7iLyczETQgO1GB0UuixNasyWs4IhGRW6Xj5VIlHzNSFPCNm_O9kU2OsRow=w1385-h1039-s-no-gm?authuser=0"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://lh3.googleusercontent.com/pw/AP1GczPAAt1oNAE5aiFouoghdBbyARSW27f5weoo19O-Th4saOq20B9w1WsQrjExuuioq_0DDVBfc2yAFC2DUoRT5ytxCh021PXO8M8BGQohe12vR05RSRXNsDXA5yeCw5esHoU-SQeS5JSnD840LbllySOE=w1559-h1039-s-no-gm?authuser=0"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://lh3.googleusercontent.com/pw/AP1GczPFf9ipD8nKBIiiZwPpTl4sJXjQkcHHny9y-i62FfLaU2CjMkJke7pxvtLybYSaUSk6L93byglp5ZMr98_R36m5dVPyTIs20kjWJXTfMfSIEOT2UGDkkUT6zaqpd99LYZwTq3SkgLSUV-IUC0tS0f2Y=w1559-h1039-s-no-gm?authuser=0"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-4.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-5.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-6.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-7.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-8.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-9.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-10.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-11.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                                 <button>
// //                                   <picture>
// //                                     <img
// //                                       width="309"
// //                                       height="309"
// //                                       src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-12.webp"
// //                                       alt="A cute cat"
// //                                       loading="lazy"
// //                                     />
// //                                   </picture>
// //                                 </button>
// //                               </div>
                
// //                               {/* <dialog class="dialog" id="dialog">
// //                                 <div class="dialogContainer">
// //                                   <div class="carousel" id="carousel">
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-1.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-2.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-3.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-4.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-5.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-6.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-7.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
                
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-8.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
                
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-9.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-10.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-11.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                     <picture>
// //                                       <img
// //                                         width="309"
// //                                         height="309"
// //                                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-12.webp"
// //                                         alt="A cute cat"
// //                                         loading="lazy"
// //                                       />
// //                                     </picture>
// //                                   </div>
// //                                   <div class="controls">
// //                                     <button
// //                                       class="controls-btn preview"
// //                                       onclick="carousel.scrollBy(-100, 0)"
// //                                     >
// //                                       <i class="ri-arrow-left-circle-fill"></i>
// //                                     </button>
// //                                     <button
// //                                       class="controls-btn next"
// //                                       onclick="carousel.scrollBy(100, 0)"
// //                                     >
// //                                       <i class="ri-arrow-right-circle-fill"></i>
// //                                     </button>
// //                                   </div>
// //                                   <button id="closeDialogBtn" class="closeDialog">
// //                                     <i class="ri-close-line"></i>
// //                                   </button>
// //                                 </div>
// //                               </dialog> */}
// //                             </section>
// //                           </div>
// //                         </main>
                
                       
                
                
                
// //                       </div>
// //                       <div className=" border-t-2 border-white h-fit w-full grid mb-4 font-defmono   ">
// //                         <h1
// //                           id="contact"
// //                           className="text-center pt-4 text-3xl pc:text-4xl underline underline-offset-8 pb-4 "
// //                         >
// //                           CONTACT US!
// //                         </h1>
// //                         <div className="grid grid-flow-row pc:grid-flow-col justify-around   ">
// //                           <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] d">
// //                             <p className=" underline underline-offset-8 pl-4 text-2xl pc:text-3xl">
// //                               Office
// //                             </p>
// //                             <a
// //                               href="http://photonics.cusat.ac.in/contact-us/"
// //                               className="hover:scale-105 transition duration-200  p-2 w-fit"
// //                             >
// //                               The Director,
// //                               <br />
// //                               International School of Photonics, <br />
// //                               Cochin University of Science and Technology, <br />
// //                               South Kalamassery, <br />
// //                               Cochin -22.
// //                             </a>
// //                           </div>
// //                           <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] ">
// //                             <p className=" underline underline-offset-8  text-2xl pc:text-3xl">
// //                               Student Committee
// //                             </p>
// //                             <div className="text-left grid grid-flow-row pt-4 gap-2">
// //                               <a
// //                                 href="tel:628-234-9338"
// //                                 className="hover:scale-110 transition duration-200 w-fit"
// //                               >
// //                                 Arjun Rajeev: 62823 49338
// //                               </a>
// //                               <a
// //                                 className="hover:scale-110 transition duration-200 w-fit"
// //                                 href="tel:807-584-2406"
// //                               >
// //                                 Arun Raj K: 80758 42406




























// //                               </a>
// //                               <a
// //                                 className="hover:scale-110 transition duration-200 w-fit"
// //                                 href="tel:701-244-1198"
// //                      <div className="w-full h-screen">
// //                      <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
// //                         GALLERY
// //                       </h1>
// //                       <div className="w-full mobile:h-auto pc:h-screen p-8  text-center text-2xl">
                      
// //                         <p>
// //                             Explore images from our previous programs and events! This page is currently under development, so stay tuned for updates.
// //                         </p>
// //                         {/* <img
// //                           src={cusatimg}
// //                           alt=""
// //                           className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
// //                         /> */}
// //                         {/* <p>
// //                         <a
// //                                 href="https://near.tl/sm/xKzwit1E7"
// //                                 className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
// //                               >
// //                                 Yadhukrishan ET: 70124 41198
// //                                 <p></p>
// //                               </a>
// //                             </div>
// //                           </div>
// //                         </div>
                
// //                         <p className="text-2xl pc:text-2xl text-center border-t-2 border-white pt-2  ">
// //                           Copyright <span className="text-red-600">ETCHNEW</span> 2025
// //                         </p>
// //                         </p> */}
// //                       </div>
// //                      </div>
// //                      <Footer/>
// //                     </div>
// //                     // <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
// //                     //   <div className="w-full h-screen">
// //                     //     <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
// //                     //         GALLERY
// //                     //     </h1>
// //                     //     <div className="w-full mobile:h-auto pc:h-screen p-8  text-center text-2xl">
                
// //                     //         <p>
// //                     //             Explore images from our previous programs and events! This page is currently under development, so stay tuned for updates.
// //                     //         </p>
// //                     //         {/* <img
// //                     //           src={cusatimg}
// //                     //           alt=""
// //                     //           className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
// //                     //         /> */}
// //                     //         {/* <p>
// //                     //         <a
// //                     //                 href="https://near.tl/sm/xKzwit1E7"
// //                     //                 className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
// //                     //               >
// //                     //                 <p></p>
// //                     //               </a>
// //                     //         </p> */}
// //                     //     </div>
// //                     //   </div>
// //                     //  <Footer/>
// //                     // </div>
                
                    
// //                   );
// //                 };
                
                
// //         {/* <img
// //           src={cusatimg}
// //           alt=""
// //           className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
// //         /> */}
// //         {/* <p>
// //         <a
// //                 href="https://near.tl/sm/xKzwit1E7"
// //                 className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
// //               >
// //                 Yadhukrishan ET: 70124 41198
// //                 <p></p>
// //               </a>
// //             </div>
// //           </div>
// //         </div>

// //         <p className="text-2xl pc:text-2xl text-center border-t-2 border-white pt-2  ">
// //           Copyright <span className="text-red-600">ETCHNEW</span> 2025
// //         </p>
// //         </p> */}
// //       </div>
// //      </div>
// //      <Footer/>
// //     </div>
// //     // <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
// //     //   <div className="w-full h-screen">
// //     //     <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
// //     //         GALLERY
// //     //     </h1>
// //     //     <div className="w-full mobile:h-auto pc:h-screen p-8  text-center text-2xl">

// //     //         <p>
// //     //             Explore images from our previous programs and events! This page is currently under development, so stay tuned for updates.
// //     //         </p>
// //     //         {/* <img
// //     //           src={cusatimg}
// //     //           alt=""
// //     //           className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
// //     //         /> */}
// //     //         {/* <p>
// //     //         <a
// //     //                 href="https://near.tl/sm/xKzwit1E7"
// //     //                 className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
// //     //               >
// //     //                 <p></p>
// //     //               </a>
// //     //         </p> */}
// //     //     </div>
// //     //   </div>
// //     //  <Footer/>
// //     // </div>

    
// //   );

// import React from "react";
// import Footer from "./footer";
// import cusatimg from "../images/cusat2.png";
// // import "./gallery.js";
// import "./animation.css"


// function Gallery() {
// // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
//   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
//   return (
//     <><div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
//       <div className="w-full h-screen">
//         <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
//           GALLERY
//         </h1>
//         <div className="w-full mobile:h-auto pc:h-screen p-8  text-center text-2xl">
//           {/* <img
//       src={cusatimg}
//       alt=""
//       className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
//     /> */}
//           {/* <p>
//     <a
//             href="https://near.tl/sm/xKzwit1E7"
//             className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
//   <div className="w-full h-[200vh]">

//     {/* <header>
//       <h1>Showcase</h1>
//     </header> */}
//           <main>
//             <div>
//               <section>

//                 <div class="imageGallery">
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://lh3.googleusercontent.com/pw/AP1GczP6kNbqaFcfJ5ghcoZAEXZ9x-UfIL5sIfz9Zk84GpwXU1_7Yq2gy_EaPV8gwKsjQPFMe6Jw1ryCSoAD_2SZwIWZxaHyrvpa7iLyczETQgO1GB0UuixNasyWs4IhGRW6Xj5VIlHzNSFPCNm_O9kU2OsRow=w1385-h1039-s-no-gm?authuser=0"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://lh3.googleusercontent.com/pw/AP1GczPFf9ipD8nKBIiiZwPpTl4sJXjQkcHHny9y-i62FfLaU2CjMkJke7pxvtLybYSaUSk6L93byglp5ZMr98_R36m5dVPyTIs20kjWJXTfMfSIEOT2UGDkkUT6zaqpd99LYZwTq3SkgLSUV-IUC0tS0f2Y=w1559-h1039-s-no-gm?authuser=0"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://lh3.googleusercontent.com/pw/AP1GczPFf9ipD8nKBIiiZwPpTl4sJXjQkcHHny9y-i62FfLaU2CjMkJke7pxvtLybYSaUSk6L93byglp5ZMr98_R36m5dVPyTIs20kjWJXTfMfSIEOT2UGDkkUT6zaqpd99LYZwTq3SkgLSUV-IUC0tS0f2Y=w1559-h1039-s-no-gm?authuser=0"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-4.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-5.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-6.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-7.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-8.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-9.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-10.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-11.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                   <button>
//                     <picture>
//                       <img
//                         width="309"
//                         height="309"
//                         src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-12.webp"
//                         alt="A cute cat"
//                         loading="lazy" />
//                     </picture>
//                   </button>
//                 </div>

//                 {/* <dialog class="dialog" id="dialog">
//       <div class="dialogContainer">
//         <div class="carousel" id="carousel">
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-1.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-2.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-3.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-4.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-5.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-6.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-7.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>

//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-8.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>

//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-9.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-10.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-11.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//           <picture>
//             <img
//               width="309"
//               height="309"
//               src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/image-gallery/images/img-12.webp"
//               alt="A cute cat"
//               loading="lazy"
//             />
//           </picture>
//         </div>
//         <div class="controls">
//           <button
//             class="controls-btn preview"
//             onclick="carousel.scrollBy(-100, 0)"
//           >
//             <i class="ri-arrow-left-circle-fill"></i>
//           </button>
//           <button
//             class="controls-btn next"
//             onclick="carousel.scrollBy(100, 0)"
//           >
//             <i class="ri-arrow-right-circle-fill"></i>
//           </button>
//         </div>
//         <button id="closeDialogBtn" class="closeDialog">
//           <i class="ri-close-line"></i>
//         </button>
//       </div>
//     </dialog> */}
//               </section>
//             </div>
//           </main>





//         </div>
//         <div className=" border-t-2 border-white h-fit w-full grid mb-4 font-defmono   ">
//           <h1
//             id="contact"
//             className="text-center pt-4 text-3xl pc:text-4xl underline underline-offset-8 pb-4 "
//           >
//             CONTACT US!
//           </h1>
//           <div className="grid grid-flow-row pc:grid-flow-col justify-around   ">
//             <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] d">
//               <p className=" underline underline-offset-8 pl-4 text-2xl pc:text-3xl">
//                 Office
//               </p>
//               <a
//                 href="http://photonics.cusat.ac.in/contact-us/"
//                 className="hover:scale-105 transition duration-200  p-2 w-fit"
//               >
//                 The Director,
//                 <br />
//                 International School of Photonics, <br />
//                 Cochin University of Science and Technology, <br />
//                 South Kalamassery, <br />
//                 Cochin -22.
//               </a>
//             </div>
//             <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] ">
//               <p className=" underline underline-offset-8  text-2xl pc:text-3xl">
//                 Student Committee
//               </p>
//               <div className="text-left grid grid-flow-row pt-4 gap-2">
//                 <a
//                   href="tel:628-234-9338"
//                   className="hover:scale-110 transition duration-200 w-fit"
//                 >
//                   Arjun Rajeev: 62823 49338
//                 </a>
//                 <a
//                   className="hover:scale-110 transition duration-200 w-fit"
//                   href="tel:807-584-2406"
//                 >
//                   Arun Raj K: 80758 42406
//                 </a>
//                 <a
//                   className="hover:scale-110 transition duration-200 w-fit"
//                   href="tel:701-244-1198"
//                 >
//                   <p></p>
//                   Yadhukrishan ET: 70124 41198
//                 </a>
//               </div> */
//             </div>
//           </div>
//         </div>

//         <p className="text-2xl pc:text-2xl text-center border-t-2 border-white pt-2  ">
//           Copyright <span className="text-red-600">ETCHNEW</span> 2025
//         </p>
//       </div>
//     </div><Footer /></>
    
    
//     // <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
//     //   <div className="w-full h-screen">
//     //     <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
//     //         GALLERY
//     //     </h1>
//     //     <div className="w-full mobile:h-auto pc:h-screen p-8  text-center text-2xl">

//     //         <p>
//     //             Explore images from our previous programs and events! This page is currently under development, so stay tuned for updates.
//     //         </p>
//     //         {/* <img
//     //           src={cusatimg}
//     //           alt=""
//     //           className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
//     //         /> */}
//     //         {/* <p>
//     //         <a
//     //                 href="https://near.tl/sm/xKzwit1E7"
//     //                 className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
//     //               >
//     //                 <p></p>
//     //               </a>
//     //         </p> */}
//     //     </div>
//     //   </div>
//     //  <Footer/>
//     // </div>

//   );
// }

// export default Gallery;
