import React, { useState, useEffect } from "react";
import Footer from "./footer";
import { Link, useLocation } from "react-router-dom";
import cusatimg from "../images/cusat2.png";
import football from "../images/neonfootball3.jpg";
import lasertag from "../images/lasertag.jpg";
import UVroom from "../images/UV_room.JPG";
import Aos from "aos";
import './animation.css';


function Newsletter() {
  const [isJanVisible, setIsJanVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("access") === "jan") {
      setIsJanVisible(true);
    }
  }, [location.search]);

  return (
    <div className="bg-black justify-items-center font-defmono text-white grid items-center w-full overflow-x-clip ">
      <div className="w-full h-auto justify-items-center ">
        <h1 className="text-center justify-items-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
          NEWSLETTER
        </h1>
        <div className="w-full justify-items-center mobile:h-auto pc:h-auto p-8 text-center text-2xl">
        {!isJanVisible && (
        <div>
          
            <p className="w-full">
              Explore the history and real-world impact of photonics and optics.
              Stay updated on the latest advancements, try hands-on experiments,
              and enjoy exclusive interviews with experts. Participate in fun
              activities like crosswords, puzzles, and quizzes to win prizes.
            </p>

            <Link
              to="/newsletter_registration"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className="pl-2 hover:translate-y-2 transition duration-200 ease-in-out hover:text-red-600 hover:underline"
            >
              <div className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100 cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700">
                Register
              </div>
            </Link>
          </div>
          )}
          

          {/* Conditionally render "Newsletter Jan" based on the query parameter */}
          {isJanVisible && (
          <div>
          {/* <Link
            to="/newsletter_jan"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="pl-2 hover:translate-y-2 transition duration-200 ease-in-out hover:text-red-600 hover:underline"
          >
            <div className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100 cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700">
              Newsletter Jan
            </div>
          </Link> */}
          <div className="pt-8 pc:pt-24 grid w-full h-fit pc:mb-48 items-center mobile:p-8 font-defmono justify-items-center">
            {/* <h1 className="text-5xl mobile:text-6xl grid justify-center snap-center font-defmono pt-10 mt-10">
              Events
            </h1> */}
            <div className="scrollbar-custom overflow-x-scroll pc:w-[80%] w-full pb-[10px]">
              <div className="flex pc:gap-[40px] gap-[20vw]">
                {/* Card 1 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={lasertag} alt="Laser Tag" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter Jan
                      </p>
                      {/* <p className="text-xs text-left">
                        Highlights for Jan events
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Card 2 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={football} alt="Football" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter Feb
                      </p>
                      {/* <p className="text-xs text-left">
                        Updates and events for Feb!
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Card 3 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={UVroom} alt="UV Room" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter Mar
                      </p>
                      {/* <p className="text-xs text-left">
                        Check out the events march
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Card 4 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={lasertag} alt="Laser Tag" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter Apr
                      </p>
                      {/* <p className="text-xs text-left">
                        Updates for April events!
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Card 5 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={football} alt="Football" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter May
                      </p>
                      {/* <p className="text-xs text-left">
                        Check out May's events!
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Card 6 */}
                <div className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                  <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                    <div className="mobile:w-3/4 grid place-items-center">
                      <div className="resize">
                        <img className="h-[80px] w-auto" src={UVroom} alt="UV Room" />
                      </div>
                    </div>
                    <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                      <p className="text-xs text-black ">
                        Newsletter Jun
                      </p>
                      {/* <p className="text-xs text-left">
                        June events and news!
                      </p> */}
                    </div>
                  </div>
                </div>
        
                {/* Repeat the same structure for the remaining cards */}
                {/* Cards 7 - 12 */}
                {['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                  <div key={index} className="border-white w-[20%] flex-shrink-0 h-auto content-start grid place-items-center">
                    <div className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip">
                      <div className="mobile:w-3/4 grid place-items-center">
                        <div className="resize">
                          <img className="h-[80px] w-auto" src={lasertag} alt="Laser Tag" />
                        </div>
                      </div>
                      <div className="p-2 bg-white pc:w-[15vw] w-[28vw] text-black relative">
                        <p className="text-xs text-black ">
                          Newsletter {month}
                        </p>
                        {/* <p className="text-xs text-left">
                          Events and updates for {month}!
                        </p> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Newsletter;
